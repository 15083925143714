// do this whenever you load a page
// so also when loading a new history.js page
// often occurs in <main> or changing navigations

// imports --------------------------------------------------------------------
import * as Vars from './0-vars.js';
import * as Once from './2-once.js';
import * as Resize from './4-resize.js';
import * as Scroll from './5-scroll.js';
// import * as Example from './example.js';
import * as MyHistory from './hr-history-v2.0.js';

// Import Swiper JS
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay} from 'swiper/modules';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation.min.css';
import 'swiper/modules/pagination.min.css';
import 'swiper/modules/autoplay.min.css';
Swiper.use([Navigation, Pagination, Autoplay]);

// exported variables ---------------------------------------------------------
export const navItemClasses = ['footer__link', 'nav__menu-item'];

// local variables ------------------------------------------------------------

// new page function ----------------------------------------------------------
export const setPage = () => {
  // sets all the pages
  // console.log('setPage');

  // fix for local testing, before history in code ------------------------
  if (window.location.hostname.indexOf('192.168') > -1) {
    // replace in links
    const anchors = document.querySelectorAll('a');
    anchors.forEach(element => {
      const href = element.getAttribute('href');
      if (href) {
        const newLink = href.replace('localhost', window.location.hostname);
        element.setAttribute('href', newLink);
      }
    });

    // replace in forms
    const forms = document.querySelectorAll('form');
    forms.forEach(element => {
      const action = element.getAttribute('action');
      if (action) {
        const newLink = action.replace('localhost', window.location.hostname);
        element.setAttribute('action', newLink);
      }
    });

    // replace in images
    const imageEls = document.querySelectorAll('img');
    imageEls.forEach(imageEl => {
      const src = imageEl.getAttribute('data-src');
      if (src) {
        const newLink = src.split('localhost').join(window.location.hostname);
        imageEl.setAttribute('data-src', newLink);
      }

      const srcset = imageEl.getAttribute('data-srcset');
      if (srcset) {
        const newLink = srcset.split('localhost').join(window.location.hostname);
        imageEl.setAttribute('data-srcset', newLink);
      }
    });

    $baseUrl = $baseUrl.replace('localhost', window.location.hostname);
    $url = $url.replace('localhost', window.location.hostname);
    $assets = $assets.replace('localhost', window.location.hostname);
  }

  // set active menu item -------------------------------------
  const cleanUrl = window.location.href.split('?')[0].split('#')[0];
  const navItemsEls = document.querySelectorAll('a');

  navItemsEls.forEach(navItemEl => {
    navItemClasses.forEach(navItemClass => {
      if (!navItemEl.classList.contains(navItemClass)) return;

      navItemEl.classList.remove(navItemClass + '--active');
      const href = navItemEl.getAttribute('href');

      if (href === cleanUrl) {
        navItemEl.classList.add(navItemClass + '--active');
      } else {
        for (let j = 0; j < $arPostTypes.length; j++) {
          if (
            cleanUrl.indexOf('/' + $arPostTypes[j] + '/') > 0
          && href == $url + $arPostTypes[j] + '/'
          ) {
            navItemEl.classList.add(navItemClass + '--active');
          }
        }
      }
    });
  });

  // setters -----------------------------------------------
  if (typeof MyHistory !== 'undefined') MyHistory.historySetPage();

  let trustBox = document.querySelector('.trustpilot-widget');
  if (trustBox && window.Trustpilot) {
    window.Trustpilot.loadFromElement(trustBox);
  }

  // break element into seperate elements per word
  const headingEls = document.querySelectorAll('h1, h2');
  headingEls.forEach(headingEl => {
    const spanEls = headingEl.querySelectorAll('.heading__bold, .heading__regular');
    spanEls.forEach(spanEl => {
      const words = spanEl.textContent.split(' ');
      let newHtml = '';
      words.forEach(word => {
        newHtml += `<span class="word"><div class="word__text fader fader--up fader--repeat fader--top">${word}</div></span> `;
      });
      spanEl.innerHTML = newHtml;
    });
  });

  // initialize Swiper
  const highlights = new Swiper('.highlights__swiper', {
    direction: 'horizontal',
    loop: true,
    speed: 450,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    slidesPerView: 1,
    spaceBetween: 5,
    centeredSlides: true,
    touchStartPreventDefault: Vars.blTouch ? true : false, // so aim moves when swiping
    breakpoints: {
      // all 1 px bigger than css breakpoint
      // when window width is >= 600px
      600: {
        slidesPerView: 1.5,
        centeredSlides: true,
        spaceBetween: 5,
      },
      // when window width is >= 801px
      801: {
        slidesPerView: 2,
        spaceBetween: Vars.gutterMedium,
        centeredSlides: false,
      },
      // when window width is >= 1024px
      1024: {
        slidesPerView: 2,
        spaceBetween: Vars.gutterMedium,
        centeredSlides: false,
      },
      // when window width is >= 1201px
      1201: {
        slidesPerView: 3,
        centeredSlides: true,
        spaceBetween: 5,
      },
    }
  });


  // switch ($pageType) {
  // }

  Resize.setResize(true);

  const errorEls = document.querySelectorAll('.error');
  let top = 10000000;
  errorEls.forEach(errorEl => {
    const rect = errorEl.getBoundingClientRect();
    const elTop = rect.top + Scroll.scrollTop;
    if (elTop < top)   top = rect.top + Scroll.scrollTop;  
  });

  if (top < 10000000) Scroll.animateScrollTop(top - Resize.navHeight - 100);

  // Once.fader -----------------------------------------------------
  // has to be called, because there is new content
  // must be after setting slick, because new elements are created
  setTimeout(() => {
    if ('fader' in Once) {
      Once.fader.blockResizeObserver = false; // was blocked in history
      Once.fader.setFaders();
      Resize.setBlockResizeObserver(false); // was blocked in history
    }
  }, 100); // more than once js fader delay
};

// document ready listener
document.addEventListener('DOMContentLoaded', setPage);
